.error-page {
  margin-top: 80px;

  .logo {
    text-align: center;
  }

  .box {
    border-radius: 3px;
    margin: 0 auto;
    margin-top: 40px;
    background: white;
    max-width: 500px;
    padding: 40px;
    color: darkred;
    font-size: 13px;

    .text {
      font-size: 12px;
    }
  }
}
