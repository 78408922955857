// Bootstrap overrides should go here.

$custom: #17a2b8 !default;

$theme-colors: map-merge(
  (
    "custom": $custom
  ),
  $theme-colors
);

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
