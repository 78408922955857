.versionDisclaimer {
  text-align: center;

  > .btn {
    margin-top: 12px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    color: Gray;
  }
}

.versionDisclaimer-label {
  margin-top: 19px;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 12px;
  color: Gray;
  text-align: center;
}

.versionDisclaimer-modal {
  .table {
    tr > td:last-of-type,
    tr > th:last-of-type {
      width: 125px;
    }
  }

  .badge {
    height: 1.2rem;
  }
  .list-group-item-text {
    margin-bottom: 0.25rem;

    p {
      margin-bottom: 0;
    }
  }
}