.login {
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;

  &.customError {
    .errorBox {
      border-radius: 5px 5px 0 0;
      background: white;
      width: 312px;
      padding: 24px;
      padding-bottom: 0;
  
      .errorBox__content {
        padding: 18px;
        background-color: #fff799;
        border: solid 1px #fbaf5d;
        color: #4f4c46;
        border-radius: 3px;
        font-family: Arial,Helvetica,sans-serif;
        font-size: 13px;
  
        .errorBox__content__title {
          display: block;
          font-weight: bold;
          font-family: Arial,Helvetica,sans-serif;
          font-size: 14px;
        }
  
        ul {
          padding-left: 20px;
        }
      }
    }
  }
  
  .logo {
    margin-bottom: 45px;
    margin-top: 48px;
  }

  #oktaWidgetWrapper {
    margin: 0 auto;

    #okta-sign-in {
      margin-top: 0;
      margin-bottom: 0;
      box-shadow: none;
      border-radius: 5px;
      border: none;
      width: 312px;
      font-family: Arial, Helvetica, sans-serif !important;

      .auth-content {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 35px;

        .okta-form-label label,
        .custom-checkbox label {
          font-weight: normal;
          color: #424040;
        }

        .okta-form-label label {
          font-size: 16px !important;
        }

        .custom-checkbox label {
          font-size: 14px !important;
        }

        .auth-footer {
          .link.help.js-help,
          .link.help.js-back {
            font-weight: normal;
            color: #424040;
            text-decoration: underline;
            font-size: 14px !important;

            &:focus {
              box-shadow: none;
            }
          }

          .help-links.js-help-links {
            .link {
              font-weight: normal;
              color: #424040;
            }
          }
        }
      }

      .focused-input {
        box-shadow: none;
        border-color: #bbb;
      }
        
      .okta-form-input-field {
        background-color: #e4e4e4;
        border-radius: 5px;
      }

      .okta-form-input-field:hover {
        border-color: #bbb;
      }

      .okta-form-input-field input {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
      }

      .okta-form-title,
      .okta-sign-in-header {
        display: none;
      }

      .o-form-button-bar {
        padding-bottom: 10px;
        box-shadow: none;
      }

      .password-expired {
        .okta-form-title {
          display: block;
          font-weight: normal;
          text-align: left;
          padding: 18px;
          background-color: #fff799;
          border: solid 1px #fbaf5d;
          color: #4f4c46;
          border-radius: 3px;
          font-family: Arial,Helvetica,sans-serif;
          font-size: 13px;
          margin-top: -10px;

          &::before {
            content: 'Update Account';
            background: transparent;
            display: block;
            width: auto;
            font-family: Arial,Helvetica,sans-serif;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .okta-form-subtitle {
          display: none;
        }
      }

      .o-form-button-bar.focused-input {
        margin-bottom: 0;
      }

      .custom-checkbox.focused-input {
        box-shadow: none;
      }

      #okta-signin-submit {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
      }

      .o-form-button-bar .button.button-primary,
      .button.email-button {
        box-shadow: none;
        background: #e1e1e1;
        border-color: #cdcdcd;
        background: linear-gradient(white, #e1e1e1);
        color: black;
        height: 40px;
        line-height: 40px;
        
        &:hover {
          background: linear-gradient(white, #c3c3c3);
        }

        &:disabled {
          background: rgb(244, 244, 244);
          cursor: not-allowed;
        }
      }

      .okta-form-infobox-error.infobox.infobox-error {
        padding-left: 18px !important;
        background-color: #fff799;
        border: solid 1px #fbaf5d;
        color: #4f4c46;
        padding-top: 34px !important;

        &::before {
          content: 'Validation Error';
          background: transparent;
          width: auto;
          padding-left: 18px;
          padding-top: 12px;
          font-family: Arial,Helvetica,sans-serif;
          font-size: 14px;
          font-weight: bold;
        }

        .icon.error-16 {
          display: none;
        }
      }
    }
  }

  .forgotPasswordWrapper {
    display: none;
    background: white;
    width: 312px;
    border-radius: 5px;
    padding: 24px;
    font-family: Arial, Helvetica, sans-serif;

    .input-group {
      margin-bottom: 16px;
      font-size: 16px;

      span {
        display: block;
        width: 100%;
        margin-bottom: 4px;
        color: #424040;
      }

      input {
        display: block;
        width: 100%;
        background: rgb(228, 228, 228);
        border: 1px solid rgb(187, 187, 187);
        border-radius: 3px;
        padding: 6px 8px;
        color: #2f3f4a;
      }

      .error {
        color: #d93934;
        padding-left: 20px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        padding-top: 8px;
        position: relative;

        &::before {
          content: "\e055";
          color: #e34843;
          display: block;
          font-family: okticon;
          font-size: 16px;
          font-style: normal !important;
          font-weight: 300 !important;
          position: absolute;
          text-indent: 0;
          white-space: normal;
          left: 0;
          top: 4px;
        }
      }
    }

    button.btn-gray {
      width: 100%;
      display: block;
      height: 40px;
      border: 1px solid rgb(205, 205, 205);
      border-radius: 3px;
      background: linear-gradient(rgb(255, 255, 255), rgb(225, 225, 225));
      font-size: 13px;

      &:hover {
        background: linear-gradient(white, #c3c3c3);
      }
    }

    button.btn-link {
      display: block;
      margin-top: 10px;
      color: rgb(66, 64, 64);
      font-size: 14px;
      text-decoration: underline;
      background: transparent;
      padding: 0;
      border: none;
    }

    .responseScreen p {
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      color: rgb(92, 105, 113);
    }
  }

  .customLinks {
    background-color: white;
    width: 312px;
    border-radius: 0 0 3px 3px;
    padding: 0 24px;
    padding-bottom: 24px;

    > button.btn-link {
      font-weight: normal;
      color: #424040;
      text-decoration: underline;
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      background: transparent;
      padding: 0;
      border: none;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: none;

      &.expanded {
        display: inherit;
      }

      li {
        margin-top: 10px;

        button.btn-link {
          font-weight: normal;
          color: #424040;
          font-size: 12px;
          font-family: Arial, Helvetica, sans-serif;
          background: transparent;
          padding: 0;
          border: none;
        }

        a {
          font-weight: normal;
          color: #424040;
          font-size: 12px;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}

.login {
  #oktaWidgetWrapper {
    #okta-sign-in {
      .auth-content {
        .auth-footer {
          display: none;
        }
      }
    }
  }
}

#oktaWidgetWrapper {
  #okta-sign-in {
    border-radius: 0 0 5px 5px;

    .auth-content {
      padding-top: 15px;
    }

    .okta-form-infobox-error.infobox.infobox-error {
      display: none !important;
    }
  }
}

// Display the error that is inside of the widget
.login.widgetError:not(.customError) {
    .okta-form-infobox-error.infobox.infobox-error {
    display: block;
  }
}

// Display the forgot password step
.login.forgotPassword {
  #oktaWidgetWrapper {
    display: none;
  }

  .forgotPasswordWrapper {
    display: block;
  }
}

// Fix borders when there are custom widget links and a custom error
.login.widgetLinks.customError {
  #oktaWidgetWrapper {
    #okta-sign-in {
      border-radius: 0;
    }
  }
}

// Fix borders when there are custom widget links
.login.widgetLinks {
  #oktaWidgetWrapper {
    #okta-sign-in {
      border-radius: 5px 5px 0 0;
    }
  }
}

// Fix borders when there is a custom error
.login.customError {
  #oktaWidgetWrapper {
    #okta-sign-in {
      border-radius: 0 0 5px 5px;
    }
  }

  .forgotPasswordWrapper {
    border-radius: 0 0 5px 5px;
  }
}
