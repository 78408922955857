.component_header {
  display: flex;

  > div {
    flex-grow: 1;
    border-bottom: 4px solid #333;
    background: #e7e7e7;
    display: flex;

    img {
      align-self: center;
      margin-left: 5px;
    }
  }
}
